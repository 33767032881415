@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*

To get this list of colors inject jQuery at http://www.google.com/design/spec/style/color.html#color-color-palette

Then, run this script to get the list@mixin (function(){
  var colors = {}, main = {};
  $(".color-group")@mixin each(function(){
    var color = $(this).find(".name").text().trim().toLowerCase().replace(" ", "-");
    colors[color] = {};

    $(this)@mixin find(".color").not(".main-color").each(function(){
      var shade = $(this).find(".shade").text().trim(),
          hex   = $(this).find(".hex").text().trim();

      colors[color][shade] = hex;
    });
    main[color] = color + "-" + $(this).find(".main-color .shade").text().trim();

  });
  var LESS = "";
  $@mixin each(colors, function(name, shades){
    LESS += "\n\n";
    $@mixin each(shades, function(shade, hex){
      LESS += "$" + name + "-" + shade + ": " + hex + ";\n" !default;
    });
    if (main[name]) {
      LESS += "$" + name + ": " + main[name] + ";\n" !default;
    }
  });
  console.log(LESS);
})();


*/
/* ANIMATION */
/* SHADOWS */
/* ANIMATION */
/* SHADOWS */
/* "bootstrap-material-design/sass/_shadows"; */
/* Shadows (from mdl http://www.getmdl.io/) */
/* Slider */
.slick-loading .slick-list {
  background: #fff url("../vector/loader/rings.svg") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "FontAwesome";
  font-size: 45px;
  line-height: 1;
  color: #009fda;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 15px; }
  .slick-prev:before {
    content: ""; }

.slick-next {
  right: 25px; }
  .slick-next:before {
    content: ""; }

/* Dots */
.slick-slider {
  margin-bottom: 0; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: "FontAwesome";
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #009fda;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #009fda;
      opacity: 0.25; }

.slide {
  overflow: hidden; }
  .slide:focus {
    outline: none; }
  .slide .h1, .slide .h2,
  .slide h1, .slide h2 {
    font-weight: 400; }
